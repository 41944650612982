
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { mixins } from "vue-class-component";
import { DomainStoreMixin } from "@/mixins/Stores/DomainStoreMixin";
import { SslCertStoreMixin } from "@/mixins/Stores/SslCertStoreMixin";
import AppConfig from "@/config/AppConfig";
import { bus } from "@/init/_bus";
import { DOMAINS_WERE_CHECKED } from "@/config/Consts";
import IDomain from "@/interfaces/IDomain";

@Component({
  components: { DefaultTableLayout },
  mixins: [ListMixin],
})
export default class DomainList extends mixins(
  DomainStoreMixin,
  ListMixin,
  SslCertStoreMixin
) {
  public name = "DomainList";

  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  /*
  @DomainModule.Getter("getDataList")
  protected list: any;

  @DomainModule.Action("findAll")
  protected loadList: any;

  @DomainModule.Getter("getIsLoading")
  protected isLoading: any;


   */
  protected get resource(): string {
    return AppConfig.DOMAIN_RESOURCE;
  }

  protected loadList(params: any) {
    this.findAllDomainItemsAction(params);
  }

  protected initEventListeners(): void {
    bus.$on(DOMAINS_WERE_CHECKED, () => {
      this.onReloadTable();
    });
  }

  protected registerListeners(): void {
    this.$echo
      .channel("Domains")
      .listen("Domains\\DomainsSslWereChecked", (e: any) => {
        this.onReloadTable();
      });
  }

  protected init() {
    this.setDomainItem(null);
    this.setSslCertItem(null);
    this.registerListeners();
    this.initEventListeners();
  }
}
