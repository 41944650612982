import { Component, Vue } from "vue-property-decorator";
import AppConfig from "@/config/AppConfig";
import { namespace } from "vuex-class";

const SslCertsModule = namespace(AppConfig.SSL_CERTIFICATE_STORE);

@Component({})
export class SslCertStoreMixin extends Vue {
  /**
   * Actions
   */
  @SslCertsModule.Action("create")
  protected createSslCertItemAction: any;

  @SslCertsModule.Action("findOne")
  protected findOneSslCertItemAction: any;

  @SslCertsModule.Action("update")
  protected updateSslCertItemAction: any;

  @SslCertsModule.Action("findAll")
  protected findAllSslCertItemsAction: any;

  @SslCertsModule.Action("resetSave")
  protected resetSaveSslCertAction: any;

  @SslCertsModule.Action("resetList")
  protected resetListSslCertAction: any;

  @SslCertsModule.Action("del")
  protected delSslCertItemAction: any;

  @SslCertsModule.Action("findAllSubresource")
  protected findAllSubresourceSslCertItemsAction: any;

  @SslCertsModule.Action("setDataList")
  protected setDataListSslCertAction: any;

  @SslCertsModule.Action("upload")
  protected uploadSslCertAction: any;

  /**
   * Getters
   */
  @SslCertsModule.Getter("getError")
  protected getSslCertError: any;

  @SslCertsModule.Getter("getIsLoading")
  protected getSslCertIsLoading: any;

  @SslCertsModule.Getter("getDataItem")
  protected getSslCertItem: any;

  @SslCertsModule.Getter("getSuccess")
  protected getSslCertSuccess: any;

  @SslCertsModule.Getter("getDataList")
  protected getSslCertList: any;

  @SslCertsModule.Getter("getTotal")
  protected getSslCertTotal: any;

  @SslCertsModule.Getter("getDeletedSuccess")
  protected getSslCertDeletedSuccess: any;

  /**
   * Mutations
   */
  @SslCertsModule.Mutation("IS_LOADING")
  protected setSslCertLoading: any;

  @SslCertsModule.Mutation("SET_ERROR")
  protected setSslCertError: any;

  @SslCertsModule.Mutation("SET_SUCCESS")
  protected setSslCertSuccess: any;

  @SslCertsModule.Mutation("SET_DATA_ITEM")
  protected setSslCertItem: any;

  @SslCertsModule.Mutation("SET_DATA_LIST")
  protected setSslCertList: any;

  @SslCertsModule.Mutation("SET_TOTAL")
  protected setSslCertTotal: any;

  @SslCertsModule.Mutation("SET_DELETED_SUCCESS")
  protected setSslCertDeletedSuccess: any;

  // Additional

  @SslCertsModule.Action("createNewCsr")
  protected createNewSslCertCsrAction: any;
}
