
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import AppConfig from "@/config/AppConfig";

const DomainModule = namespace(AppConfig.DOMAIN_STORE);
const SslCertModule = namespace(AppConfig.SSL_CERTIFICATE_STORE);

@Component({
  components: { DefaultTableLayout },
  mixins: [ListMixin],
})
export default class SslCertList extends Vue {
  protected name = "SslCertList";

  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  @SslCertModule.Getter("getDataList")
  protected list: any;

  @SslCertModule.Action("findAll")
  protected loadList: any;

  @SslCertModule.Getter("getIsLoading")
  protected isLoading: any;

  protected get resource(): string {
    return AppConfig.SSL_CERTIFICATE_RESOURCE;
  }

  protected init() {}
}
