import { Component, Vue } from "vue-property-decorator";
import AppConfig from "@/config/AppConfig";
import { namespace } from "vuex-class";

const DomainsModule = namespace(AppConfig.DOMAIN_STORE);

@Component({})
export class DomainStoreMixin extends Vue {
  /**
   * Actions
   */
  @DomainsModule.Action("create")
  protected createDomainItemAction: any;

  @DomainsModule.Action("findOne")
  protected findOneDomainItemAction: any;

  @DomainsModule.Action("update")
  protected updateDomainItemAction: any;

  @DomainsModule.Action("findAll")
  protected findAllDomainItemsAction: any;

  @DomainsModule.Action("resetSave")
  protected resetSaveDomainAction: any;

  @DomainsModule.Action("resetList")
  protected resetListDomainAction: any;

  @DomainsModule.Action("del")
  protected delDomainItemAction: any;

  @DomainsModule.Action("findAllSubresource")
  protected findAllSubresourceDomainItemsAction: any;

  @DomainsModule.Action("setDataList")
  protected setDataListDomainAction: any;

  @DomainsModule.Action("upload")
  protected uploadDomainAction: any;

  /**
   * Getters
   */
  @DomainsModule.Getter("getError")
  protected getDomainError: any;

  @DomainsModule.Getter("getIsLoading")
  protected getDomainIsLoading: any;

  @DomainsModule.Getter("getDataItem")
  protected getDomainItem: any;

  @DomainsModule.Getter("getSuccess")
  protected getDomainSuccess: any;

  @DomainsModule.Getter("getDataList")
  protected getDomainList: any;

  @DomainsModule.Getter("getTotal")
  protected getDomainTotal: any;

  @DomainsModule.Getter("getDeletedSuccess")
  protected getDomainDeletedSuccess: any;

  /**
   * Mutations
   */
  @DomainsModule.Mutation("IS_LOADING")
  protected setDomainLoading: any;

  @DomainsModule.Mutation("SET_ERROR")
  protected setDomainError: any;

  @DomainsModule.Mutation("SET_SUCCESS")
  protected setDomainSuccess: any;

  @DomainsModule.Mutation("SET_DATA_ITEM")
  protected setDomainItem: any;

  @DomainsModule.Mutation("SET_DATA_LIST")
  protected setDomainList: any;

  @DomainsModule.Mutation("SET_TOTAL")
  protected setDomainTotal: any;

  @DomainsModule.Mutation("SET_DELETED_SUCCESS")
  protected setDomainDeletedSuccess: any;
}
